import Header from "./header";
import FooterComponent from "./footer";
import CarouselComponent from "./carusel";
import ResponsiveList from "./responsiveList";
import ListaRegiuni from "./listaRegiuni";

function App() {
  return (
    <div className=" justify-center">
      <Header />
      <div className="container mx-auto mt-3 justify-between gap-6 text-center text-blue-100 lg:w-full md:w-3/5 font-nunito">
        <ResponsiveList />
        <ListaRegiuni />
        <CarouselComponent />
      </div>
      <FooterComponent />
    </div>
  );
}

export default App;
