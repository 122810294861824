import React, { useState } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";

const CarouselComponent = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const importAll = (r) => {
    let images = {};
    r.keys().forEach((item) => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  };

  const imageFiles = importAll(
    require.context("./images", false, /\.(png|jpe?g|svg)$/),
  );

  const imageArray = Object.keys(imageFiles).map((key) => imageFiles[key]);

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % imageArray.length);
  };

  const prevImage = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + imageArray.length) % imageArray.length,
    );
  };

  return (
    <div className="relative w-full lg:max-w-2xl max-w-xl mx-auto px-2">
      <div className="overflow-hidden rounded-lg shadow-lg">
        <img
          src={imageArray[currentIndex]}
          alt={`Slide ${currentIndex + 1}`}
          className="w-full lg:h-full h-96 object-cover" // Adjust height as needed
        />
      </div>
      <button
        onClick={prevImage}
        className="absolute left-0 top-1/2 -translate-y-1/2 bg-black/50 text-white p-2 rounded-full"
      >
        <ChevronLeft />
      </button>

      <button
        onClick={nextImage}
        className="absolute right-0 top-1/2 -translate-y-1/2 bg-black/50 text-white p-2 rounded-full"
      >
        <ChevronRight />
      </button>

    </div>
  );
};

export default CarouselComponent;
