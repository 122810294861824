import { ReactComponent as Logo } from "./logo-desktop.svg";

import fbColor from "./fb-color.svg";
import instaColor from "./insta-color.svg";
import tikColor from "./tiktok-color.svg";

export default function Header() {
  return (
    <header className="bg-[#effdff] flex items-center text-black text-lg py-1 border-b-8 border-[#84BFE9] font-hussar">
      <div className="container flex justify-between items-center mx-auto">
        <div className="flex-shrink-0">
          <Logo className="lg:h-12 h-8" alt="logo" />
        </div>

        {/* content on the right */}
        <div className="flex flex-col items-end ml-auto">
          <div className="flex items-center gap-4">
            <h3 className="font-semibold">Sună acum!</h3>
            <p className="text-lg hover:bg-orange-400 hover:text-white p-2 inline-block rounded-md">
              +40 799 024 597
            </p>
          </div>
          <div className="flex items-center gap-2">
            <img src={fbColor} alt="Facebook" className="w-8 h-8" />
            <img src={instaColor} alt="Instagram" className="w-8 h-8" />
            <img src={tikColor} alt="TikTok" className="w-8 h-8" />
          </div>
        </div>
      </div>
    </header>
  );
}
